import { Fragment, useContext, useState, useEffect } from "react";
import { ApiContext } from "../context/api";
import ReleaseItem from "./release-item";
import { formatDate } from "../utils/date";

export default function ReleaseList() {
  const [state] = useContext(ApiContext);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const releases = state.api.releases(state.project);
    const groups = releases.reduce((groups, release) => {
      if (release.is_pinned) {
        if (!groups.is_pinned) {
          groups.is_pinned = [];
        }

        groups.is_pinned.push(release);

        return groups;
      }

      const month = formatDate(release.released_at, "MMMM YYYY");

      if (!groups[month]) {
        groups[month] = [];
      }

      groups[month].push(release);

      return groups;
    }, {});

    setGroups(groups);
  }, [state.project, state.api]);

  if (!Object.keys(groups).length) {
    return (
      <div className="no-result">
        <strong>No Releases</strong>
      </div>
    );
  }

  return Object.entries(groups).map(([title, releases], i) => (
    <Fragment key={`release-${title}`}>
      {i !== 0 && (
        <small key={title} className="break">
          {title}
        </small>
      )}
      <div className="release-list">
        {releases.map((release) => (
          <ReleaseItem key={`release-${release.id}`} release={release} />
        ))}
      </div>
    </Fragment>
  ));
}
