import { useEffect, useState, useContext } from 'react';

import Filter from './filter';
import Loading from './loading';
import ReleaseList from './release-list';
import RoadmapList from './roadmap-list';
import { ApiContext } from '../context/api';
import { ThemeContext } from '../context/theme';
import { ReactComponent as IconExternal } from '../assets/external.svg';

const widget = window.widget;

function Main() {
  const [state] = useContext(ApiContext);
  const setTheme = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState(widget.options ?? {});
  const [showRoadmap, setShowRoadmap] = useState(options.showRoadmap);

  const onViewToggle = () => {
    document.querySelector('main.page-container').scrollTo(0, 0);
    setShowRoadmap(!showRoadmap);
  };

  useEffect(() => {
    if (state.isLoading || state.error) {
      return;
    }

    widget.onReady();
  }, [state.isLoading, state.error]);

  useEffect(() => {
    if (state.isLoading || state.error) {
      return;
    }

    const roadmap = state.api.roadmap();
    const releases = state.api.releases();
    const projects = state.api.projects();

    widget.onLoad({ roadmap, releases, projects });
  }, [state.isLoading, state.error, state.api]);

  useEffect(() => {
    const onShow = () => setIsOpen(true);
    const onHide = () => setIsOpen(false);
    const onUpdate = (options) => {
      setOptions(options);
      setTheme(options.theme);
      setShowRoadmap(options.showRoadmap);
    };

    widget.on('show', onShow);
    widget.on('hide', onHide);
    widget.on('update', onUpdate);

    return () => {
      widget.off('show', onShow);
      widget.off('hide', onHide);
      widget.off('update', onUpdate);
    };
  }, []);

  if (!isOpen) {
    return <></>;
  }

  if (state.isLoading) {
    return <Loading />;
  }

  if (state.error) {
    console.error('[ONSET] ', state.error);
    return (
      <div className="no-result">
        <strong>There was a problem loading your widget.</strong>
      </div>
    );
  }

  const organization = state.organization;
  const allowSubscribers =
    options.allowSubscribers ?? organization.allow_subscribers;
  const showToggle = organization.has_releases && organization.has_roadmap;
  const colorPrimary = options.color ?? organization.color ?? '#3e45eb';
  const colorYiq = options.colorYiq ?? organization.color_yiq ?? '#FFFFFF';
  const title = options.title
    ? options.title
    : organization.has_releases
    ? 'Releases'
    : 'Roadmap';

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          :root {
            --color-yiq: ${colorYiq};
            --color-primary: ${colorPrimary};
          }
      `,
        }}
      ></style>

      <header className="header">
        <div className="page-container">
          <a
            target="_blank"
            href={organization.url}
            className="header__link"
            rel="noopener noreferrer"
          >
            <IconExternal />
          </a>
          {showToggle && (
            <label className="header__toggle">
              <input
                type="checkbox"
                checked={showRoadmap}
                onChange={onViewToggle}
              />
              <span>Releases</span>
              <span>Roadmap</span>
            </label>
          )}
          {!showToggle && <strong>{title}</strong>}
          <button onClick={() => widget.hide()} className="header__close">
            &times;
          </button>
        </div>
      </header>

      <Filter
        baseURL={organization.url}
        allowSubscribers={allowSubscribers}
        hideProjects={options.hideProjects}
      />

      <main className="page-container">
        <div className="content">
          {showRoadmap ? <RoadmapList /> : <ReleaseList />}
        </div>
      </main>

      <footer className="footer">
        Powered by{' '}
        <a
          title="Onset"
          target="_blank"
          href="https://www.onset.io"
          rel="noopener noreferrer"
        >
          Onset
        </a>
        .
      </footer>
    </>
  );
}

export default Main;
