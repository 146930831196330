import { useState } from 'react';

import { getCookie, setCookie } from './cookie';

export function useVotes() {
  const [votes, setVotes] = useState(getVotes());

  const setVote = async (id) => {
    const newVotes = updateVotes(id);
    setVotes(newVotes);
  };

  return [votes, setVote];
}

export function getVotes() {
  const data = getCookie('onset:roadmap_votes');
  return new Set(JSON.parse(data || null));
}

export function updateVotes(id) {
  const votes = getVotes();
  votes.add(id);

  const data = Array.from(votes);
  setCookie('onset:roadmap_votes', JSON.stringify(data));

  return votes;
}
