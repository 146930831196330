import React, { useEffect, useState, useContext } from 'react';
import { ApiContext } from '../context/api';
import { useReactions } from '../utils/reactions';

const emojis = [
  {
    emoji: '😞',
    name: 'Disappointed',
    value: 'disappointed',
  },
  {
    emoji: '😐',
    name: 'Neutral',
    value: 'neutral',
  },
  {
    emoji: '😃',
    name: 'Smiley',
    value: 'smiley',
  },
];

const Reaction = ({ reaction, selected, onReact }) => {
  const isSelected = reaction.value === selected;

  const onClick = (e) => {
    e.preventDefault();

    if (selected !== reaction.value) {
      onReact(reaction.value);
    }
  };

  return (
    <a
      href="#"
      onClick={onClick}
      style={{ opacity: !selected || isSelected ? 1 : 0.5 }}
    >
      <span>{reaction.emoji}</span>
    </a>
  );
};

function ReleaseReact({ release }) {
  const [state] = useContext(ApiContext);
  const [reactions, setReaction] = useReactions();
  const [selected, setSelected] = useState();

  useEffect(() => {
    setSelected(reactions.get(release.id));
  }, [release.id, reactions]);

  const onReact = async (reaction) => {
    await state.api.react(release.id, reaction);
    setReaction(release.id, reaction);
  };

  return (
    <div className="release__react">
      {emojis.map((reaction, key) => (
        <Reaction
          key={key}
          onReact={onReact}
          reaction={reaction}
          selected={selected}
        />
      ))}
    </div>
  );
}

export default ReleaseReact;
