import { useState, useEffect, useContext } from 'react';

import { ReactComponent as IconDown } from '../assets/down.svg';
import { ReactComponent as IconMail } from '../assets/mail.svg';

import { ApiContext } from '../context/api';

function Filter(props) {
  const [state, dispatch] = useContext(ApiContext);
  const [project, setProject] = useState();
  const url = `${props.baseURL}?show_subscribe=true`;

  const onSelect = (project) => {
    document.querySelector('main.page-container').scrollTo(0, 0);
    dispatch({ project, type: 'SET_PROJECT' });
  };

  useEffect(() => {
    if (state.project) {
      setProject(state.api.projects(state.project)[0]);
    } else {
      setProject(null);
    }
  }, [state.project, state.api]);

  return (
    <section className="filters">
      {props.allowSubscribers && (
        <a
          href={url}
          title="Subscribe To Updates"
          className="filters__subscribe"
        >
          <IconMail /> Subscribe
        </a>
      )}

      {!props.hideProjects && (
        <div className="filters__projects">
          <strong className="filters__projects__trigger">
            {project?.name ?? 'All Projects'}
            <IconDown width={18} height={18} alt="Projects" />
          </strong>
          <ul className="filters__projects__nav">
            <li onClick={() => onSelect(null)}>All Projects</li>

            {state.projects.map((p) => (
              <li onClick={() => onSelect(p.slug)} key={p.slug}>
                {p.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </section>
  );
}

export default Filter;
