import { Fragment, useEffect, useState, useContext } from "react";

import { ReactComponent as IconUpdate } from "../assets/update.svg";
import { ReactComponent as IconCalendar } from "../assets/calendar.svg";
import { ReactComponent as IconProject } from "../assets/project.svg";
import { ReactComponent as IconCheckBox } from "../assets/check-box.svg";
import { ReactComponent as IconVote } from "../assets/vote.svg";
import { ReactComponent as IconCancel } from "../assets/cancel.svg";

import { ApiContext } from "../context/api";
import { truncate } from "../utils/helpers";
import { useVotes } from "../utils/votes";
import { formatDate } from "../utils/date";

const Header = ({ feature }) => {
  return (
    <div className="roadmap__header">
      <h4 className="roadmap__title">
        <a
          target="_blank"
          href={feature.url}
          title={feature.title}
          rel="noopener noreferrer"
        >
          {feature.title}
        </a>
      </h4>

      <ul className="roadmap__state">
        <li>
          <span
            className="roadmap__status"
            style={{ backgroundColor: feature.stage.color }}
          ></span>
          {feature.stage.title}
        </li>
        <li>
          <ProgressChart percent={feature.progress} />
        </li>
        <li>
          <IconUpdate width={18} height={18} alt="Updates" />
          {feature.updates.length}
        </li>
      </ul>

      <ul className="roadmap__meta">
        {feature.released_at ? (
          <li>
            <IconCheckBox alt="Released At" />
            {formatDate(feature.released_at, "LL")}
          </li>
        ) : feature.created_at ? (
          <li>
            <IconCalendar alt="Created At" />
            {formatDate(feature.created_at, "LL")}
          </li>
        ) : undefined}

        {feature.project && (
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={feature.project.url}
              title={feature.project.name}
            >
              <IconProject alt="Project" />
              {feature.project.name}
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

const Labels = ({ labels, id }) => {
  if (!labels.length) {
    return null;
  }

  return (
    <div className="roadmap__labels">
      {labels.map((label, key) => (
        <Fragment key={`feature-${id}-label-${key}`}>
          <a
            target="_blank"
            href={label.url}
            className="label"
            rel="noopener noreferrer"
          >
            <i
              className="label__circle"
              style={{ backgroundColor: label.color }}
            ></i>
            {label.name}
          </a>
        </Fragment>
      ))}
    </div>
  );
};

const ProgressChart = ({ percent = 0 }) => {
  const max = 100 - percent;

  return (
    <>
      <div
        className={`progress-chart progress-chart--${percent}`}
        style={{
          background: `conic-gradient(currentColor 0% ${percent}%, transparent ${percent}% ${max}%)`,
        }}
      ></div>
      {percent}%
    </>
  );
};

const VoteButton = ({ feature }) => {
  const [state] = useContext(ApiContext);
  const [votes, setVote] = useVotes();
  const [isVoted, setIsVoted] = useState(false);
  const upvoteCount = state.organization.upvote_count;

  useEffect(() => {
    setIsVoted(votes.has(feature.id));
  }, [feature.id, votes]);

  const onVote = async () => {
    if (upvoteCount) {
      feature.votes++;
    }

    await state.api.vote(feature.id);
    setVote(feature.id);
  };

  if (feature.status === "canceled") {
    return (
      <button
        type="button"
        disabled={true}
        className={`roadmap__vote__button roadmap__vote__button--canceld ${
          upvoteCount ? "" : "roadmap__vote__button--slim"
        }`}
      >
        <IconCancel /> {upvoteCount && feature.votes}
      </button>
    );
  }

  if (feature.status === "released") {
    return (
      <button
        type="button"
        disabled={true}
        className={`roadmap__vote__button roadmap__vote__button--released ${
          upvoteCount ? "" : "roadmap__vote__button--slim"
        }`}
      >
        <IconCheckBox />
        {upvoteCount && feature.votes}
      </button>
    );
  }

  if (isVoted) {
    return (
      <button
        type="button"
        disabled={true}
        className={`roadmap__vote__button roadmap__vote__button--voted ${
          upvoteCount ? "" : "roadmap__vote__button--slim"
        }`}
      >
        <IconVote />
        {upvoteCount && feature.votes}
      </button>
    );
  }

  return (
    <button
      type="button"
      onClick={onVote}
      className={`roadmap__vote__button roadmap__vote__button--vote ${
        upvoteCount ? "" : "roadmap__vote__button--slim"
      }`}
    >
      <IconVote />
      {upvoteCount && feature.votes}
    </button>
  );
};

export default function RoadmapItem({ feature }) {
  const [state] = useContext(ApiContext);

  return (
    <article className={`roadmap roadmap--${feature.status}`}>
      <div
        style={{ backgroundColor: feature.stage.color }}
        className={`roadmap__banner roadmap__banner--${feature.status}`}
      ></div>

      {state.organization.upvote && (
        <div className="roadmap__vote">
          <VoteButton feature={feature} />
        </div>
      )}

      <div className="roadmap__info">
        <Header feature={feature} />

        {feature.description_text?.length > 0 && (
          <>
            <div className="roadmap__description">
              <p>{truncate(feature.description_text, 75)}</p>
            </div>
          </>
        )}

        <Labels labels={feature.labels} id={feature.id} />
      </div>
    </article>
  );
}
