import { useEffect } from 'react';
import { createContext, useReducer } from 'react';
import { loadPage } from '@onsetio/browser';

export const ApiContext = createContext();

function reducer(state, action) {
  switch (action.type) {
    case 'PAGE_SUCCESS':
      return {
        ...state,
        error: null,
        api: action.api,
        isLoading: false,
        projects: action.api.projects(),
        organization: action.api.organization,
      };

    case 'PAGE_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case 'SET_PROJECT':
      return {
        ...state,
        project: action.project,
      };

    default:
      throw new Error();
  }
}

export function ApiContextProvider({ children, page, project }) {
  const [state, dispatch] = useReducer(reducer, {
    project,
    error: null,
    isLoading: true,
    organization: null,
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        const api = await loadPage(page);
        dispatch({ api, type: 'PAGE_SUCCESS' });
      } catch (error) {
        dispatch({ type: 'PAGE_ERROR', error });
      }
    };

    loadData();

    const interval = setInterval(loadData, 300000);
    return () => clearInterval(interval);
  }, [page]);

  return (
    <ApiContext.Provider value={[state, dispatch]}>
      {children}
    </ApiContext.Provider>
  );
}
