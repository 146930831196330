import { Fragment } from "react";

import { ReactComponent as IconCalendar } from "../assets/calendar.svg";
import { ReactComponent as IconProject } from "../assets/project.svg";
import { ReactComponent as IconVersion } from "../assets/version.svg";
import { ReactComponent as IconPinned } from "../assets/pinned.svg";

import ReleaseReact from "./release-react";
import { formatDate } from "../utils/date";

const Hero = ({ release }) => {
  if (!release.hero_image) {
    return null;
  }

  return (
    <div className="release__hero">
      <a
        target="_blank"
        href={release.url}
        title={release.title}
        rel="noopener noreferrer"
      >
        <img
          alt={release.title}
          title={release.title}
          src={release.hero_image}
        />
      </a>
    </div>
  );
};

const Header = ({ release }) => {
  return (
    <div className="release__header">
      {release.is_pinned && (
        <div className="release__pinned">
          <IconPinned alt="Pinned" />
          Pinned Release
        </div>
      )}

      <h4 className="release__title">
        <a
          target="_blank"
          href={release.url}
          title={release.title}
          rel="noopener noreferrer"
        >
          {release.title}
        </a>
      </h4>

      <Author author={release.author} />

      <ul className="release__meta">
        <li>
          <IconCalendar alt="Released At" />
          {formatDate(release.released_at, "LL")}
        </li>
        {release.project && (
          <li>
            <a
              target="_blank"
              className="text-muted"
              rel="noopener noreferrer"
              href={release.project.url}
              title={release.project.name}
            >
              <IconProject alt="Project" />
              {release.project.name}
            </a>
          </li>
        )}
        {release.version && (
          <li>
            <IconVersion alt="Version" />v{release.version}
          </li>
        )}
      </ul>
    </div>
  );
};

const ChangeList = ({ changes, id }) => {
  if (!changes.length) {
    return null;
  }

  return (
    <div className="release__changes">
      <table>
        <tbody>
          {changes.map((change, key) => (
            <tr key={`release-${id}-change-${key}`}>
              <td width="auto">
                <span
                  style={{ color: change.color }}
                  className="release__changes__type"
                >
                  {change.title}
                </span>
              </td>
              <td
                width="100%"
                dangerouslySetInnerHTML={{ __html: change.description }}
              ></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Labels = ({ labels, id }) => {
  if (!labels.length) {
    return null;
  }

  return (
    <div className="release__labels">
      {labels.map((label, key) => (
        <Fragment key={`release-${id}-label-${key}`}>
          <a
            target="_blank"
            href={label.url}
            className="label"
            rel="noopener noreferrer"
          >
            <i
              className="label__circle"
              style={{ backgroundColor: label.color }}
            ></i>
            {label.name}
          </a>
        </Fragment>
      ))}
    </div>
  );
};

const Author = ({ author }) => {
  if (!author) {
    return null;
  }

  return (
    <div className="release__author">
      <div
        className="release__author_avatar"
        style={{
          backgroundColor: author.avatar_color,
          backgroundImage: `url('${author.avatar_image}')`,
        }}
      >
        {author.avatar_image ? "" : author.avatar_text}
      </div>
      {author.name}
    </div>
  );
};

const Footer = ({ release }) => {
  return (
    <div className="release__footer">
      <ReleaseReact release={release} />
    </div>
  );
};

export default function ReleaseItem({ release }) {
  return (
    <article className="release" id={release.id}>
      <Hero release={release} />
      <Header release={release} />

      {release.description?.length > 0 && (
        <div
          className="release__content"
          dangerouslySetInnerHTML={{ __html: release.description }}
        ></div>
      )}

      <ChangeList changes={release.change_list} id={release.id} />
      <Labels labels={release.labels} id={release.id} />
      <Footer release={release} />
    </article>
  );
}
