import { Fragment, useContext, useState, useEffect } from "react";
import { ApiContext } from "../context/api";
import RoadmapItem from "./roadmap-item";

export default function RoadmapList() {
  const [state] = useContext(ApiContext);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const features = state.api.roadmap(state.project);

    const groups = Object.entries(state.organization.roadmap_stages).reduce(
      (groups, [slug, stage]) => {
        const groupFeatures = features.filter(({ status }) => status === slug);

        if (slug === "released") {
          groupFeatures.sort(({ released_at: a }, { released_at: b }) => {
            return new Date(b).getTime() - new Date(a).getTime();
          });
        } else {
          groupFeatures.sort((a, b) => (a.rank > b.rank ? -1 : 1));
        }

        if (groupFeatures.length) {
          groups[slug] = {
            title: stage.title,
            features: groupFeatures,
          };
        }

        return groups;
      },
      {}
    );

    setGroups(groups);
  }, [state.project, state.api, state.organization.roadmap_stages]);

  if (!Object.keys(groups).length) {
    return (
      <div className="no-result">
        <strong>No Roadmap</strong>
      </div>
    );
  }

  return Object.entries(groups).map(([slug, { title, features }], i) => (
    <Fragment key={`feature-${slug}`}>
      {i !== 0 && <small className="break">{title}</small>}
      <div className="roadmap-list">
        {features.map((feature) => (
          <RoadmapItem key={`feature-${feature.id}`} feature={feature} />
        ))}
      </div>
    </Fragment>
  ));
}
