import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './components/app';
import './styles/main.css';

Sentry.init({
  dsn: 'https://1483b942cb494226b561f4033ed60ad4@o340545.ingest.sentry.io/2163313',
});

ReactDOM.render(<App />, document.getElementById('onset-root'));
