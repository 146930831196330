import { useState } from 'react';

import { getCookie, setCookie } from './cookie';

export function useReactions() {
  const [reactions, setReactions] = useState(getReactions());

  const setReaction = async (id, reaction) => {
    const newReactions = updateReactions(id, reaction);
    setReactions(newReactions);
  };

  return [reactions, setReaction];
}

export function getReactions() {
  const data = getCookie('onset:release_reactions');
  return new Map(JSON.parse(data || null));
}

export function updateReactions(id, reaction) {
  const reactions = getReactions();
  reactions.set(id, reaction);

  const data = Array.from(reactions);
  setCookie('onset:release_reactions', JSON.stringify(data));

  return reactions;
}

export function isEmojiSupported() {
  const node = document.createElement('canvas');

  if (
    !node.getContext ||
    !node.getContext('2d') ||
    typeof node.getContext('2d').fillText !== 'function'
  ) {
    return false;
  }

  const ctx = node.getContext('2d');
  ctx.textBaseline = 'top';
  ctx.font = '32px Arial';
  ctx.fillText('\ud83d\ude03', 0, 0);

  return ctx.getImageData(16, 16, 1, 1).data[0] !== 0;
}
