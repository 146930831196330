import React, { createContext, useEffect } from 'react';

export const ThemeContext = createContext({});

function ThemeProvider({ children, theme: defaultTheme }) {
  useEffect(() => {
    updateTheme(defaultTheme);
  }, [defaultTheme]);

  const updateTheme = (theme) => {
    if (theme === 'auto') {
      theme = window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';
    }

    document.querySelector('html').setAttribute('data-theme', theme);
  };

  return (
    <ThemeContext.Provider value={updateTheme}>
      {children}
    </ThemeContext.Provider>
  );
}

export default ThemeProvider;
