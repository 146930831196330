import { useEffect, useState } from 'react';
import ThemeProvider from '../context/theme';
import { ApiContextProvider } from '../context/api';
import Main from './main';

const widget = window.widget;

function App() {
  const [page, setPage] = useState(widget?.options?.page);

  useEffect(() => {
    widget.on('reload', (options) => setPage(options.page));
  }, []);

  return (
    <>
      <ApiContextProvider page={page} project={widget?.options?.project}>
        <ThemeProvider theme={widget?.options?.theme}>
          <Main />
        </ThemeProvider>
      </ApiContextProvider>
    </>
  );
}

export default App;
